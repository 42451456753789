/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
    var TOOLS = {
        lastScrollTop: $(window).scrollTop(),

        linkPrompter: function($o) {
            var _o = $.extend({
                url: window.location.href,
                prompt: 'Copy link, then click OK.'
            },$o);
            prompt(_o.prompt, _o.url);
        },

        /*
    passParams checks the URL for ref parameters and passes the parameters to next designated link
     */

        setParams: function($target, $params) {
            $($target).each(function() {
                var _link   = $(this),
                    _href   = (_link.attr('href') ? _link.attr('href'): ''),
                    _hash   = (_href.split('#')[1] ? '#'+_href.split('#')[1] : ''),
                    _url    = (_hash ? _href.split('#')[0] : _href),
                    _params = $params;

                // Experiment: Don't overwrite params in the examined link if it already has a referral_code
                var overwrite_params = _href.indexOf('referral_code=') < 0;
                if (overwrite_params) {
                    _link.attr('href', _url + _params + _hash);
                }
            });
        },

        passParams: function($o) {
            var _o = $.extend({
                item: 'a.passParams, .custom-logo-link, .passParams > a',
                trackKey: 'referral_code',
                trackValue: 'indiegogo'
            },$o);

            if ($.query.get(_o.trackKey)) {
                TOOLS.setParams(_o.item, $.query.toString());
            }
            else {
                TOOLS.setParams(_o.item, $.query.set(_o.trackKey,_o.trackValue).toString());
            }
        },

        activeMenu: function($o) {
            var _menu = $($o);
            _menu.find('a').on('click', function(){
                $(this).addClass('active');
                $(this).parent().siblings().find('a').removeClass('active');
            });
        },

        smoothScroll: function() {

            $('a[href*="#"]:not([href="#"]):not(.nav-link)').click(function() {
                if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {

                    var target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) +']');

                    if (target.length) {
                        $('html, body').animate({
                            scrollTop: (target.offset().top - 150)
                        }, 500);
                        return false;
                    }

                }
            });
        }

    };

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Gogo = {
        // All pages
        'common': {
            init: function() { // JavaScript to be fired on all pages

                $('.news__entry').on('mouseenter.gogo', function(event){
                    $(this).addClass('active');
                    $(this).parent().siblings().find('img').removeClass('active');

                    var data = $(this).data();
                    var $target = $('#' + data.target);

                    var $display = $target.find('.news-display__quote');
                    var $name = $target.find('.news-display__name');


                    var $link = (typeof data.link !== 'undefined' && data.link.length) ?
                        $('<a>').attr('href', data.link) : $('<span>');

                    $display.text(data.quote);
                    $link.text(data.name);

                    $name.empty().append($link);

                });

                $('.nav-tabs a').on('shown.bs.tab', function(event){
                    var $container = $(this).closest('.nav-tabs');

                    if ($(window).width() < 768) {
                        $container.find('li:not(.active)').hide();
                        var $tab = $container.find('li.active');
                        var $parent = $tab.parent();
                        $parent.prepend($tab.detach());
                    }
                });

                $('.nav-tabs a').on('click.gogo', function(event){
                    var $container = $(this).closest('.nav-tabs');

                    if ($(window).width() < 768) {
                        $container.find('li:not(.active)').show();
                        var $tab = $container.find('li.active');
                        var $parent = $tab.parent();
                        $parent.prepend($tab.detach());
                    }
                });

                $(window).on('resize.gogo', function(event){
                    if ($(window).width() >= 768) {
                        $('.nav-tabs li:not(.active)').show();
                    }
                    else{
                        $('.nav-tabs li:not(.active)').hide();
                    }
                });
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
                //var sr = new ScrollReveal();
                // sr.reveal('.wow');

                // Script to automatically pass URL parameters to links
                // with classes a.passParams, .custom-logo-link, .passParams > a
                TOOLS.passParams();

            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Gogo;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
